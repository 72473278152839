/* 
Day Blue: #C3D9DC
Night Blue: #131736 
*/

@font-face {
  font-family: 'RelativeProBold';
  /* Give your font a name */
  src: url('./fonts/RelativePro-Bold.ttf') format('truetype');
  /* Path to your TTF font file */
  /* You can also specify font-weight and font-style if needed */
}

@font-face {
  font-family: 'RelativeProBook';
  /* Give your font a name */
  src: url('./fonts/RelativePro-Book.ttf') format('truetype');
  /* Path to your TTF font file */
  /* You can also specify font-weight and font-style if needed */
}



body {
  font-family: 'RelativeProBook', sans-serif;
  color: #131736
}

p,
li {
  font-family: 'RelativeProBook', sans-serif;
  color: #131736
}


. background-custom {
  background-color: #c3d9dc;
}

/* Apply RelativeProBook font to specific elements, e.g., headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'RelativeProBold', sans-serif;
  color: #131736
}

.custom-heading {
  font-family: 'RelativeProBold', sans-serif;
  font-weight: 700;
  color: #131736
}

.larger-header {
  font-family: 'RelativeProBold', sans-serif;
  font-weight: 700;
  font-size: 3rem;
  /* Adjust the font size as needed */
  color: #131736
}

.btn.btn-square {
  border-radius: 0.05;
  /* Square edges */
  background-color: #131736 !important;
  /* Force background color */
  color: #C3D9DC !important;
  font-size: 16px;
  font-weight: 700
}



.swoopy-underline {
  position: relative;
  display: inline-block;
}

.swoopy-underline::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #131736;
  /* Pink color */
  bottom: -5px;
  left: 0;
  border-radius: 50%;
  transform: scaleX(1.2) translateY(-2px);
}

.app-container {
  background-color: #c3d9dc;
  /* Your desired background color */
  /* Other global styles */
}

/* Header.css */
.sticky-header {
  position: sticky;
  top: 0;
  background-color: #c3d9dc;
  padding: 10px;
  z-index: 100;
  /* Ensure the header appears above other content */
}

.sticky-header img {
  max-width: 100%;
}